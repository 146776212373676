/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */


 body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}
header {
  text-align: center;
  margin-bottom: 20px;
}
.logo {
  max-width: 200px;
  height: auto;
}
.aviso {
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.aviso a {
  color: #007BFF;
  text-decoration: none;
  font-weight: bold;
}
.aviso a:hover {
  text-decoration: underline;
}
footer {
  position: absolute;
  bottom: 10px;

  /* width: 100%; */
  font-size: 14px;
}