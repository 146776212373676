body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-right-panel {
  margin-top: 64px;
  overflow-y: auto;
}

.MuiToolbar-regular {
  height: 64px !important;
}

.map-bottom-panel {
  margin-top: 50vh !important;
  margin-left: 240px !important;
}

.map-filter-button {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.slide-pane__close {
  margin-left: 0px !important;
  padding: 0px !important;
  margin-top: 5px;
}

.slide-pane__title-wrapper {
  margin-top: 3px;
  margin-left: 0px !important;
}

.slide-pane__subtitle {
  margin-left: 2px;
}

.register-link {
  padding-top: 15px;
  font-size: 13px;
  text-decoration: none;
  color: grey;
}

.register-link:hover {
  text-decoration: underline;
}

.MuiIconButton-colorInherit .MuiIconButton-label {
  color: #6c757d;
}

.rbc-agenda-content {
  height: 270px;
  overflow-y: scroll;
}

.right-panel {
  margin-top: 64px;
  overflow-y: auto;
}

.technician-calendar .rbc-month-view {
  z-index: 0;
}

.technician-calendar .rbc-time-view {
  z-index: 0;
}

.technician-calendar .rbc-agenda-view {
  z-index: 0;
}